<template>
  <div class="mod-org" ref="mod-org">
    <div class="container-left" :style="{ height: clientHeight + 'px' }">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="orgTree"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
          :draggable="isDraggable"
          @node-drag-end="handleDragEnd"
          @node-drag-start="handleDragStart"
        ></el-tree>
      </div>
    </div>
    <div class="container-right">
      <div class="buttonOut">
        <el-button type="primary" size="small" @click="addOrgNode">{{ addOrgNodeFlag ? "保存" : "新增" }}</el-button>
        <el-button type="danger" size="small" @click="delOrgNodeWarning">删除</el-button>
        <el-button v-if="isAuth('sys:orgArch:addProvince')" type="primary" size="small" @click="addProvince"
          >新增省</el-button
        >
        <el-button v-if="false" @click="isDraggable = !isDraggable" type="primary" size="small">
          {{ isDraggable ? "已开启，点击关闭拖拽功能" : "已关闭，点击开启拖拽功能" }}
        </el-button>
        <el-button type="primary" size="small" @click="editTreeNodeName"> 修改节点名称 </el-button>
      </div>
      <div class="content">
        <el-form :model="curNode" label-width="100px" label-position="left">
          <el-form-item label="上级名称">
            <el-input
              v-model="curNode.parendName"
              class="fixedWidth400"
              size="small"
              placeholder="上级节点(不可修改)"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              v-model="curNode.nodeName"
              class="fixedWidth400"
              size="small"
              placeholder="节点名称"
              :disabled="!addOrgNodeFlag"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="类型">
            <el-select
              v-model="curNode.nodeType"
              class="fixedWidth400"
              size="small"
              placeholder="节点类型"
              :disabled="!addOrgNodeFlag"
            >
              <el-option label="省" :value="0"></el-option>
              <el-option label="市" :value="1"></el-option>
              <el-option label="区/县" :value="2"></el-option>
              <el-option label="单位" :value="3"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  name: "sys-org",
  data() {
    return {
      orgTree: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      curNode: {},
      addOrgNodeFlag: false,
      isShowAddProvince: false,
      filterText: "",
      beforeDragTreeData: [], //进行拖拽操作之前的数据
      isDraggable: false, //tree是否可拖拽
    };
  },
  computed: {
    clientHeight() {
      return document.documentElement["clientHeight"] - 122;
    },
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  mounted() {
    this.getOrgList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          Loading.close();
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      this.addOrgNodeFlag = false;
      this.curNode = JSON.parse(JSON.stringify(node));
    },
    addOrgNode() {
      if (!this.addOrgNodeFlag) {
        //点击插入,重置表单
        if (!this.curNode.id) return this.$message.warning("请选择节点!");
        this.addOrgNodeFlag = !this.addOrgNodeFlag;
        let _parendName = JSON.parse(JSON.stringify(this.curNode.nodeName));
        this.curNode.parendName = _parendName;
        this.curNode.nodeName = "";
        this.curNode.nodeType += 1;
      } else {
        //点击保存
        let _ids = this.curNode.parendIds ? this.curNode.parendIds.split(",") : [];
        _ids.push(this.curNode.id);
        let parendIds = _ids.filter((item) => !!item);
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/sys/orgArch/add"),
          method: "post",
          data: {
            nodeName: this.curNode.nodeName,
            parendId: this.curNode.id,
            parendIds: parendIds.join(),
            nodeType: this.curNode.nodeType,
          },
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.addOrgNodeFlag = !this.addOrgNodeFlag;
            this.getOrgList();
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      }
    },
    addProvince() {
      this.$prompt("请输入名称", "添加省", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入名称",
      })
        .then(({ value }) => {
          return this.$http({
            url: this.$http.adornUrl(`/sys/orgArch/addProvince?name=${value}`),
            method: "post",
          });
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.getOrgList();
        })
        .catch((err) => {
          if (err != "cancel") {
            this.$message({
              type: "error",
              message: err,
            });
          }
        });
    },
    //修改节点名称
    editTreeNodeName() {
      let _curNode = this.curNode;
      // if (
      //   this.curNode.id == "1" ||
      //   (this.curNode.parendIds.split(",")[0] == "1" &&
      //     this.curNode.nodeType < 2)
      // )
      //   return this.$message.warning("此节点不能修改!");
      if (
        _curNode.id == "1" ||
        _curNode.id == "1341622163294666753" ||
        _curNode.id == "1341626966192177153" ||
        _curNode.id == "1342371808009728002" ||
        _curNode.id == "1343761846358503425" ||
        _curNode.id == "1346640692552392706" ||
        _curNode.id == "1363388273328275458" ||
        _curNode.id == "1364398011004370946" ||
        _curNode.id == "1367358083313582082" ||
        _curNode.id == "1376354833005252609" ||
        _curNode.id == "1379637575553880065" ||
        _curNode.id == "1391681987200933890" ||
        _curNode.id == "1394120285190074370" ||
        _curNode.id == "1394834626495361026" ||
        _curNode.id == "1402100795329609729"
      ) {
        return this.$message.warning("此节点不能修改!");
      }
      if (!this.curNode.id) return this.$message.error("请选择节点!");
      this.$prompt(`当前节点名称：${this.curNode.nodeName}`, "修改节点名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入名称",
        inputPlaceholder: "请输入节点名称",
      })
        .then(({ value }) => {
          if (!this.isAuth("sys:orgArch:updateNodeName"))
            return this.$message.warning("没有权限!sys:orgArch:updateNodeName");
          return this.$http({
            url: this.$http.adornUrl(`sys/orgArch/updateNodeName`),
            method: "put",
            params: this.$http.adornParams({
              orgId: this.curNode.id,
              nodeName: value,
            }),
          });
        })
        .then((res) => {
          this.getOrgList();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    delOrgNodeWarning() {
      this.$confirm("是否删除节点", "请确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delOrgNode();
      });
    },
    delOrgNode() {
      let _curNode = this.curNode;
      if (
        _curNode.id == "1" ||
        _curNode.id == "1341622163294666753" ||
        _curNode.id == "1341626966192177153" ||
        _curNode.id == "1342371808009728002" ||
        _curNode.id == "1343761846358503425" ||
        _curNode.id == "1346640692552392706" ||
        _curNode.id == "1363388273328275458" ||
        _curNode.id == "1364398011004370946" ||
        _curNode.id == "1367358083313582082" ||
        _curNode.id == "1376354833005252609" ||
        _curNode.id == "1379637575553880065" ||
        _curNode.id == "1391681987200933890" ||
        _curNode.id == "1394120285190074370" ||
        _curNode.id == "1394834626495361026" ||
        _curNode.id == "1402100795329609729"
      ) {
        return this.$message.warning("此节点不能删除!");
      }
      const Loading = this.$mask();
      let id = this.curNode.id;
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/del"),
        method: "delete",
        params: {
          id,
        },
      })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getOrgList();
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    //开始拖拽， 保存未拖拽之前的数据
    handleDragStart() {
      this.beforeDragTreeData = JSON.parse(JSON.stringify(this.orgTree));
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      let data = null;
      console.log(dropType);
      if (dropType == "inner") {
        let _ids = dropNode.data.parendIds.split(",");
        _ids.push(dropNode.data.id);
        let parendIds = _ids.filter((item) => !!item);
        data = {
          id: draggingNode.data.id,
          nodeName: draggingNode.data.nodeName,
          nodeType: dropNode.data.nodeType + 1,
          parendId: dropNode.data.id,
          parendIds: parendIds.join(),
          parendName: dropNode.data.nodeName,
        };
      } else if (dropType == "after" || dropType == "before") {
        data = {
          id: draggingNode.data.id,
          nodeName: draggingNode.data.nodeName,
          nodeType: dropNode.data.nodeType,
          parendId: dropNode.data.parendId,
          parendIds: dropNode.data.parendIds,
          parendName: dropNode.data.parendName,
        };
      } else {
        this.orgTree = JSON.parse(JSON.stringify(this.beforeDragTreeData));
        return;
      }
      // console.log("当前节点", draggingNode.data);
      console.log("拖入节点", dropNode.data);
      console.log("新节点", JSON.parse(JSON.stringify(data)));
      this.$confirm(`确定对单位节点进行拖拽操作吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("sys/orgArch/update"),
            method: "put",
            data,
          });
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.getOrgList();
        })
        .catch((err) => {
          // this.orgTree = JSON.parse(JSON.stringify(this.beforeDragTreeData));
          this.getOrgList();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 50%;
  padding: 8px;
}
</style>